<template>
  <transition name="slide">
    <div class="content-transition h-full flex column content-between col-grow-1">
      <div class="fade-in-down h-full flex column content-between w-full">
        <div class="w-full flex col-grow-1 column content-center items-center h-full justify-center">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'WindowSuccess'
}
</script>

<style scoped>

</style>
