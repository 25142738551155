<template>
  <div class="inline-block">
    <a class="text-black" @click="$uloc.window.close($root.wid)">Fechar janela [{{count}}]</a>
  </div>
</template>

<script>
export default {
  name: 'TimerCloseWindow',
  props: {
    timer: Number
  },
  data () {
    return {
      count: this.timer || 3
    }
  },
  methods: {
    start () {
      this.$closeInterval = setInterval(() => {
        this.count = this.count - 1
        if (this.count <= 0) {
          clearInterval(this.$closeInterval)
          this.$emit('end')
        }
      }, 1000)
    }
  }
}
</script>
