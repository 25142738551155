export default function () {
  let data = this.model
  if (this.beforeSave) {
    data = this.beforeSave(data)
  }
  this.$refs.window.startLoading()
  let method = this.model.id ? this.update(this.model.id, data) : this.new(data)
  method
    .then(({data}) => {
      this.$refs.window.setSuccess(true)
      this.$nextTick(() => {
        this.$refs.window.startTimerToClose()
        this.$uloc.window.emit(this.$root.wid, 'update', this.props)
      })
    })
    .catch(error => {
      this.success = false
      this.errorMessage = error.data.message
      // this.errors = this.systemError(error)
      this.$nextTick(() => {
        this.$refs.window.stopLoading()
        this.alertApiError(error)
        // this.error = true
      })
    })
}
