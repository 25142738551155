<template>
  <window-content v-if="!loading && !errors && !success">
    <slot v-bind:data="data">
    </slot>
    <slot name="footer">
      <window-footer class="flex content-between">
        <!--<div class="flex items-center justify-center font-11">
          <div class="m-l-xs">
            Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
            status para Em breve.
          </div>
        </div>-->
        <slot name="footerLeft"></slot>
        <div class="inline-block window-actions text-right self-end col-grow">
          <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
                 @click="$uloc.window.close($root.wid)" />
          <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
                 @click="save"
                 v-shortkey="['f2']" @shortkey.native="save" />
        </div>
      </window-footer>
    </slot>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
      </div>
      <h5 class="m-t" v-if="props.id">
        {{dconfig.successUpdateTitle || 'Atualizado com sucesso!'}}
      </h5>
      <h5 class="m-t" v-else>{{dconfig.successCreateTitle || 'Registrado com sucesso!'}}</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
      </div>
    </slot>
  </window-success>
  <div class="flex col-grow-1 content-center items-center h-full justify-center" v-else>
    <slot name="loading">
      <window-loading :errors="errors" :status="loadingMessage" />
    </slot>
  </div>
</template>

<script>
import {
  WindowContent,
  // WindowBody,
  WindowFooter
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../components/layout/window/Loading'
import TimerCloseWindow from '../../components/layout/window/TimerClose'
import WindowSuccess from '../../components/layout/window/Success'

export default {
  name: 'Window',
  props: {
    props: {
      required: true
    },
    resolve: {
      type: Function,
      required: false
    },
    mock: {
      type: Object,
      required: false
    },
    config: {
      type: Object,
      required: false
    },
    loadingMessage: {
      required: false,
      default: 'Aguarde'
    },
    proccessData: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      loading: !!this.props.id,
      errors: null,
      success: false,
      dconfig: this.config || {},
      data: JSON.parse(JSON.stringify(this.mock))
    }
  },
  mounted () {
    this.props.id && this.resolve && this.resolve(this.props.id)
      .then(response => {
        let data = response.data
        if (this.proccessData) {
          this.data = this.proccessData(data)
        } else {
          let _mock = JSON.parse(JSON.stringify(this.mock))
          this.data = Object.assign(_mock, data)
        }
        this.$nextTick(() => {
          this.loading = false
          this.$emit('loaded', this.data)
        })
      })
      .catch((error) => {
        this.$uloc.dialog({
          title: 'Falha ao carregar!',
          color: 'negative',
          message: `Não conseguimos carregar, entre em contato com o administrador.`
        })
        this.loading = false
        this.errors = error.data
        console.error(error, error.data)
      })
  },
  computed: {},
  watch: {},
  methods: {
    save (e) {
      this.$emit('save', e)
    },
    startLoading () {
      this.loading = true
    },
    stopLoading () {
      this.loading = false
    },
    setSuccess (v) {
      this.success = v
    },
    startTimerToClose (v) {
      this.$nextTick(() => {
        this.$refs.timer.start()
      })
    }
  },
  components: {
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    // WindowBody,
    WindowContent
  }
}
</script>
